import "./styles.css";

export default function App() {
  return (
    <div className="App">
      <div className="main outer-box-frame">
        <iframe
          className="frame"
          src="https://www.canva.com/design/DAEbbcKpPB4/view?embed"
        ></iframe>
      </div>
    </div>
  );
}
